@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

/* body { */
    /* background-color: #000300; */
    /* background-color: white; */
/* } */ 

:root {
    --primary-dark: #5651e5;
    --primary-light: #709dff;
  }

@layer base {
    html {
      @apply scroll-smooth
    }
  
    body {
      @apply font-[Poppins]
    }

    button {
        @apply text-white border bg-indigo-600 border-indigo-600
        hover:bg-transparent hover:text-indigo-600 rounded-md
      }

      /* button {
        @apply px-4 py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white hover:bg-white hover:text-indigo-600 rounded-md
      }   */

}

